import { InsightModeIndicator } from "@/components";
import AudienceCardMenu from "@/components/atoms/audience-card-menu/audience-card-menu";
import { abbreviateNumber } from "@/helpers";

import { IAudience } from "@/interfaces";
import { reformatDate } from "@/utils";
import { LinkRounded, PeopleAlt, PieChart } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Tooltip,
  Typography,
  Stack
} from "@mui/material";
import {useTheme, darken} from "@mui/material/styles";
import React, { FC } from "react";

interface AudienceCardProps {
  audience: IAudience;
  onEdit: (audience: IAudience) => void;
  onBuildAudience: (audience: IAudience) => void;
  onRebuildAudience: (audience: IAudience) => void;
  onSelectedAudience: (audience: IAudience) => void;
  onShowDeleteDialog: (open: boolean) => void;
  onShowInsight: (id: number | undefined) => void;
  onShareAudience: (audience: IAudience) => void;
}

export const AudienceCard: FC<AudienceCardProps> = ({
  audience,
  onSelectedAudience,
  onEdit,
  onShowDeleteDialog,
  onBuildAudience,
  onRebuildAudience,
  onShowInsight,
  onShareAudience,
}) => {

  const theme = useTheme();

  const handleDeleteAudience = () => {
    onShowDeleteDialog(true);
    onSelectedAudience(audience);
  };

  const handleRebuildAudience = () => onRebuildAudience(audience);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "250px",
        height: "200px",
      }}
    >
      <CardActionArea onClick={() => onEdit(audience)}>
        <CardHeader
          avatar={
            <Stack direction="row" spacing={1}>
              {audience.shared &&
                <Tooltip title={"This audience is shared with you"}>
                  <LinkRounded color="primary"/>
                </Tooltip>
              }
              <Chip label={audience.country_iso} size="small" />
            </Stack>
          }
          action={
            <InsightModeIndicator
              id={audience.run_id}
              date={audience.run_date}
            />
          }
          sx={{ p: 1, pb: 0 }}
        />
        <CardContent
          sx={{
            height: 126,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {audience.name}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
            width="100%"
          >
            {audience.modelled_count && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <PeopleAlt sx={{ mr: 1 }} />
                <Typography>
                  {abbreviateNumber(audience.modelled_count)}
                </Typography>
              </Box>
            )}
            {audience.segment_run_id && audience.segment_run_date && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <PieChart sx={{ mr: 1 }} />
                <Typography>{audience.segment_count}</Typography>
              </Box>
            )}
          </Box>
          {audience.shared_with && audience.shared_with.length > 0 && (
            <Typography variant="body2" color="textSecondary" mt={2}>
              Shared with {audience.shared_with.length} users
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: 2,
        }}
      >
        <Box>
          {audience.run_date && (
            <Typography
              component="div"
              variant="body2"
              color={"textSecondary"}
              mt={1}
            >
              {reformatDate(audience.run_date)}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <AudienceCardMenu
            audience={audience}
            onRebuildAudience={handleRebuildAudience}
            onDeleteAudience={handleDeleteAudience}
            onShareAudience={onShareAudience}
            onBuildAudience={onBuildAudience}
            onShowInsight={onShowInsight}
          />
        </Box>
      </CardActions>
    </Card>
  );
};
