import { IAudience } from "@/interfaces";
import {
  BarChartOutlined,
  ChevronRight,
  MoreVert,
  Share,
} from "@mui/icons-material";

import Delete from "@mui/icons-material/Delete";
import RestartAlt from "@mui/icons-material/RestartAlt";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { FC, MouseEvent, useState } from "react";

interface AudienceCardMenuProps {
  audience: IAudience;
  onDeleteAudience: () => void;
  onBuildAudience: (audience: IAudience) => void;
  onRebuildAudience: (audience: IAudience) => void;
  onShowInsight: (id: number | undefined) => void;
  onShareAudience: (audience: IAudience) => void;
}
const AudienceCardMenu: FC<AudienceCardMenuProps> = ({
  audience,
  onRebuildAudience,
  onDeleteAudience,
  onShareAudience,
  onBuildAudience,
  onShowInsight,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [audienceSubmenuEl, setAudienceSubmenuEl] =
    useState<null | HTMLElement>(null);
  const [insightSubmenuEl, setInsightSubmenuEl] = useState<null | HTMLElement>(
    null
  );

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAudienceSubmenuEl(null);
    setInsightSubmenuEl(null);
  };

  const handleOpenAudienceSubmenu = (event: MouseEvent<HTMLElement>) =>
    setAudienceSubmenuEl(event.currentTarget);

  const handleCloseAudienceSubmenu = () => setAudienceSubmenuEl(null);

  const handleOpenInsightSubmenu = (event: MouseEvent<HTMLElement>) =>
    setInsightSubmenuEl(event.currentTarget);

  const handleCloseInsightSubmenu = () => setInsightSubmenuEl(null);

  const handleDeleteAudience = () => {
    onDeleteAudience();
    handleCloseMenu();
  };

  const handleRebuildAudience = () => {
    onRebuildAudience(audience);
    handleCloseMenu();
  };

  const handleShareAudience = () => {
    onShareAudience(audience);
    handleCloseMenu();
  };

  return (
    <>
      {/* Main Menu Button */}
      <Tooltip title="Audience Actions">
        <IconButton
          onClick={handleOpenMenu}
          sx={{ p: 0.5, borderRadius: "6px" }}
        >
          <MoreVert color={"primary"} />
        </IconButton>
      </Tooltip>

      {/* Main Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {/* Audience Submenu */}
        <MenuItem onClick={handleOpenAudienceSubmenu}>
          <ListItemText primary="Audience" />
          <ListItemIcon>
            <ChevronRight />
          </ListItemIcon>
        </MenuItem>

        {/* Audience Options */}
        <Menu
          anchorEl={audienceSubmenuEl}
          open={Boolean(audienceSubmenuEl)}
          onClose={handleCloseAudienceSubmenu}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <MenuItem onClick={handleRebuildAudience}>
            <ListItemIcon>
              <RestartAlt color="primary" />
            </ListItemIcon>
            <ListItemText primary="Rebuild Audience" />
          </MenuItem>
          <MenuItem onClick={handleDeleteAudience}>
            <ListItemIcon>
              <Delete color="primary" />
            </ListItemIcon>
            <ListItemText primary="Delete Audience" />
          </MenuItem>
          <MenuItem onClick={handleShareAudience}>
            <ListItemIcon>
              <Share color="primary" />
            </ListItemIcon>
            <ListItemText primary="Share Audience" />
          </MenuItem>
        </Menu>

        <Divider />

        {/* Insight Submenu */}
        <MenuItem
          onClick={handleOpenInsightSubmenu}
          disabled={!audience.run_id}
        >
          <ListItemText primary="Insight" />
          <ListItemIcon>
            <ChevronRight />
          </ListItemIcon>
        </MenuItem>

        {/* Insight Options */}
        <Menu
          anchorEl={insightSubmenuEl}
          open={Boolean(insightSubmenuEl)}
          onClose={handleCloseInsightSubmenu}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onShowInsight(audience.id);
            }}
          >
            <ListItemIcon>
              <BarChartOutlined color="primary" />
            </ListItemIcon>
            <ListItemText primary="View Insight" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onBuildAudience(audience);
            }}
          >
            <ListItemIcon>
              <RestartAlt color="primary" />
            </ListItemIcon>
            <ListItemText primary="Rebuild Insight" />
          </MenuItem>
        </Menu>
      </Menu>
    </>
  );
};

export default AudienceCardMenu;
