import { getUsers } from "@/api/audience";
import { IAudience, IUser } from "@/interfaces";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";

export interface ShareAudienceDialogProps {
  selectedAudience: IAudience;
  open: boolean;
  onClose: () => void;
  onShare: (ids: number[]) => void;
}

export const ShareAudienceDialog: React.FC<ShareAudienceDialogProps> = ({
  selectedAudience,
  open,
  onClose,
  onShare,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [personName, setPersonName] = React.useState<IUser[]>([]);
  const [usersList, setUsersList] = React.useState<IUser[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getUsers(accessToken);
      if (error) {
        console.error(error);
        setLoading(false);
        return;
      }
      if (data) {
        setLoading(false);
        setUsersList(data);
        if (
          selectedAudience.shared_with &&
          selectedAudience.shared_with.length > 0
        ) {
          const sharedUsers = data.filter((user) =>
            selectedAudience.shared_with?.includes(user.user_id)
          );
          setPersonName(sharedUsers);
        } else {
          setPersonName([]);
        }
      }
    };
    fetchData();
  }, [getAccessTokenSilently, selectedAudience]);

  const handleShare = () => {
    const names = personName.map((person) => person.user_id);
    onShare(names);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "400px",
        },
      }}
    >
      <DialogTitle>Share '{selectedAudience.name}'</DialogTitle>
      <Grid xs={12} p={2} display={"flex"} flexDirection={"column"}>
        {loading ? (
          <Grid xs={12}>
            <Skeleton variant="rounded" height={56} />
          </Grid>
        ) : (
          <Grid xs={12}>
            <Autocomplete
              multiple
              options={usersList}
              getOptionLabel={(option) => option.user_name}
              value={personName}
              onChange={(event, newValue) => {
                setPersonName(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.user_name}
                    color="primary"
                    variant={"outlined"}
                    avatar={
                      <img
                        src={option.user_image}
                        alt={option.user_name}
                        style={{ width: 20, height: 20, borderRadius: "50%" }}
                      />
                    }
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  {...props}
                >
                  <img
                    src={option.user_image}
                    alt={option.user_name}
                    style={{
                      width: 35,
                      height: 35,
                      borderRadius: "50%",
                      marginRight: 8,
                    }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant={"body1"}>
                      {option.user_name}
                    </Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>
                      {option.user_email}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add names"
                />
              )}
            />
          </Grid>
        )}

        <Grid xs={12} mt={2} display={"flex"} justifyContent={"end"}>
          <LoadingButton
            variant={"contained"}
            size={"small"}
            disabled={loading}
            onClick={handleShare}
          >
            Share Audience
          </LoadingButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};
