import { fetchData } from "@/api/utils";
import {
  ApiResponse,
  AudienceApiResponse,
  IAudience,
  IUser,
} from "@/interfaces";
import { AxiosRequestConfig } from "axios";

export const getAudiences = async (
  accessToken: string
): Promise<AudienceApiResponse> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/audience`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export const saveAudience = async (
  accessToken: string,
  audience: IAudience,
  nameOnly?: boolean
): Promise<AudienceApiResponse> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/audience`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: { ...audience, name_only: nameOnly },
  };

  return fetchData(config);
};

export const deleteAudience = async (accessToken: string, id: number) => {
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/audience/${id}`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export const buildAudience = async (
  accessToken: string,
  audience: IAudience
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/build`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: audience,
  };

  return fetchData(config);
};

export const getUsers = async (
  accessToken: string
): Promise<ApiResponse<IUser[]>> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/users`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetchData(config);
};

export const shareAudience = async (
  accessToken: string,
  audienceId: number,
  userIds: number[]
): Promise<
  ApiResponse<{
    message: string;
  }>
> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/audience/share`,
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: { id: audienceId, users: userIds },
  };

  return fetchData(config);
};
